//top of the file
import firebase from 'firebase/app';
import '@firebase/auth';
// import '@firebase/firestore';
// import '@firebase/storage';
import ReduxSagaFirebase from 'redux-saga-firebase';
import axios from 'axios';

// Initialize Firebase
var config = {
  apiKey: "AIzaSyDZy6V03J4ZZLeoLcmVdW0gHdgVBiajgpQ",
  authDomain: "prioritise-training.firebaseapp.com",
  databaseURL: "https://prioritise-training.firebaseio.com",
  projectId: "prioritise-training",
  storageBucket: "prioritise-training.appspot.com",
  messagingSenderId: "186508912904"
};
firebase.initializeApp(config);

export {firebase};

// Authenticate
export const auth = firebase.auth();

// Redux-saga-firebase
export const rsf = new ReduxSagaFirebase(firebase);

// Use Axios for User Add and Create
export const authAxios = axios.create({
    baseURL: "https://www.googleapis.com/identitytoolkit/v3/relyingparty",
    params: {
        key: config.apiKey
    },
});


// Turn off timestamp notification
// const firestore = firebase.firestore();
// const settings = {/* your settings... */ timestampsInSnapshots: true};
// firestore.settings(settings);

// FireStore
// export const db = firebase.firestore();
// export const firestoreTs = firebase.firestore.Timestamp;

// Storage
// export const storage = firebase.storage().ref();
